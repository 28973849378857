.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Slide styles */
.slide {
  width: 300px;
  height: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slide h2 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.slide p {
  margin-bottom: 20px;
  font-size: 16px;
}

/* Option styles */
.optionLabel {
  display: block;
  margin-bottom: 10px;
}

.radio {
  margin-right: 10px;
}

/* Button styles */
.button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.slider {
  width: 100%;
  margin: 20px 0;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.slide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* ... other styles ... */
}
